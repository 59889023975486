import React, { useState, useEffect, useMemo } from 'react';

const Clients = ({ clientList }) => {
  const classNames = useMemo(() => ['is-primary', 'is-dark', 'is-success', 'is-warning', 'is-error'], []);
  const [assignedClasses, setAssignedClasses] = useState([]);

  useEffect(() => {
    const assignClassNames = () => {
      let availableClasses = [...classNames];
      const newAssignedClasses = clientList.map(() => {
        if (availableClasses.length === 0) {
          availableClasses = [...classNames];
        }
        const randomIndex = Math.floor(Math.random() * availableClasses.length);
        const selectedClass = availableClasses.splice(randomIndex, 1)[0];
        return selectedClass;
      });
      setAssignedClasses(newAssignedClasses);
    };

    assignClassNames();
  }, [clientList, classNames]);

  return (
    <div>
      Thành viên đang online: {clientList.length}
      <br />
      {
        clientList.map((client, index) =>
          (client.name !== "") ?
            <div key={index} className="nes-badge">
              <span className={assignedClasses[index]}>{client.name}</span>
            </div>
            : <></>
        )
      }
    </div>
  );
}

export default Clients;