import React, { useState } from 'react';
import { socket } from '../socket';

// Your list of items
const listChat = ["nói", "đã chat", "phán rằng", "gáy", "khẳng định", "chắc chắn rằng", "trả lời"];

// Function to get a random element from the list
function getRandomElement(list) {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
}

const ChatForm = ({ username }) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    let rchat = getRandomElement(listChat);
    socket.emit('chat message', "" + username + " " + rchat + ": " + value);
    setIsLoading(false);
    setValue("");
  }

  return (
    <form onSubmit={onSubmit}>
      <div class="nes-field is-inline">
        <input onChange={e => setValue(e.target.value)} value={value} type="text" id="inline_field" class="nes-input" placeholder="Nói gì đó thú vị.." />
        <label for="inline_field"><button type="submit" disabled={isLoading} className='nes-btn'>Gửi</button></label>
      </div>
    </form>
  );
}

export default ChatForm;