import React, { useState, useEffect } from 'react';
import { socket } from '../../socket';
import Events from '../../components/Events.js';
import JoinForm from '../../components/JoinForm.js';
import Clients from '../../components/Clients.js';
import ChatForm from '../../components/ChatForm.js';
import WWCard from '../../components/WWCard.js';
import RoomLeadForm from '../../components/room-lead-form.js';
import Header from '../../components/Header.js';

const PlayGame = () => {
  const [message, setMessage] = useState([]);
  const [name, setName] = useState(localStorage.getItem('name') ? localStorage.getItem('name') : "");
  const [clients, setClients] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [card, setCard] = useState("");
  const [role, setRole] = useState("");

  const [showPanel, setShowPanel] = useState(false);



  useEffect(() => {

    function onConnection() {
      if (localStorage.getItem('name')) {
        socket.emit('join game', localStorage.getItem('name'));
      }
    }

    function onAddMessage(txt) {
      setMessage(message => [...message, txt]);
      setTimeout(() => {
        var div = document.getElementById("chat-content");
        div.scrollTop = div.scrollHeight;
      }, 50);
    }
    function onResponseToClient(roleInfo) {
      if (roleInfo !== null) {
        setCard("/img/" + roleInfo.img);
        setRole(roleInfo.name);
      } else {
        setCard("");
        setRole("");
      }
    }
    function onGetDataUser(roleInfo) {
      setName(roleInfo.name)
      localStorage.setItem('name', roleInfo.name);
    }
    function onLoadClient(clientList, clientsInRoom) {
      setClients(clientList);
    }

    function onSendRolesList(lsRoles) {
      setRolesList(lsRoles);
    }

    socket.on('connect', onConnection);
    //Gửi message xuống thanh chat.
    socket.on('chat message', onAddMessage);
    //Trả thông tin về role cho client
    socket.on('responseToClient', onResponseToClient);
    //trả về thông tin user, quyết định là admin hay không
    socket.on('dataUser', onGetDataUser);
    //Danh sách client ở trong room
    socket.on('clientsInRoom', onLoadClient);
    //
    socket.on('userLeft', onAddMessage);
    socket.on('start game', onAddMessage);
    socket.on('sendRolesList', onSendRolesList);

    return () => {
      socket.off('connect', onConnection);
      socket.off('chat message', onAddMessage);
      socket.off('responseToClient', onResponseToClient);
      socket.off('dataUser', onGetDataUser);
      socket.off('clientsInRoom', onLoadClient);
      socket.off('userLeft', onAddMessage);
      socket.off('start game', onAddMessage);
      socket.off('sendRolesList', onSendRolesList);
    };
  }, []);

  return (
    <div className="App">
      <Header name={name} />
      <div className='container'>
        <Events message={message} />
        {name === "" ?
          <>
            <JoinForm />
          </> :
          <>
            <ChatForm username={name} />
            <Clients clientList={clients} />
            <WWCard src={card} alt={role} />
            <button onClick={() => setShowPanel(!showPanel)} className='nes-btn is-primary'>
              {showPanel ? 'Ẩn cài đặt game' : 'Hiện cài đặt game'}
            </button>
            {showPanel ? <RoomLeadForm listPlayer={clients} rolesList={rolesList} /> : <></>}
          </>}
      </div>
    </div>
  );
}

export default PlayGame;