import Header from "./components/Header";

function App() {
  const generateRandomString = (length) => {
    let result = '';
    const characters = '0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  // const server = "https://play.toitinban.com/";

  return (
    <div className="app">
      <Header />
      <div className="container">
        <a href={"/" + generateRandomString(6)} className="nes-btn is-primary">Tạo game mới</a>
      </div>
    </div>
  );
}

export default App;
