import React, { useState } from 'react';
import "./event.scss";
import QRCode from 'react-qr-code';

const Events = ({ message }) => {

  const [copied, setCopied] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const copyToClipboard = () => {
    setCopied(true);
    navigator.clipboard.writeText(window.location);
    setTimeout(() => setCopied(false), 1000)
  }
  return (
    <div className='event'>
      <div className='nes-btn is-warning' onClick={() => copyToClipboard()} >Mã phòng: {window.location.pathname.replace(/\//g, '')} {copied ? <span className="tooltiptext">đã copy link</span> : ""} </div>
      <br />
      <div className="nes-container with-title is-centered is-rounded">
        <label className="title">
          <input type="checkbox" className="nes-checkbox" onChange={() => setShowQR(!showQR)} />
          <span>Hiển thị QR Code</span>
        </label>
        {showQR ? <center className='qr-code'>
          <QRCode value={window.location.href} />
        </center> : <></>}
      </div>

      <br />
      <span className="nes-text is-primary">Bảng thông tin</span>
      <div className='event-body' id='chat-content'>
        {
          message.map((event, index) =>
            <div className="nes-container is-rounded" key={index}>
              <p>{event}</p>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Events;