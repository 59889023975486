import React from 'react';

const Header = ({ name }) => {

    function resetName() {
        localStorage.removeItem('name');
        window.location.reload(); 
    }

    return (
        <header className="sticky">
            <div className="nav-brand">
                <a href="/">
                    <h1><i className="nes-jp-logo brand-logo"></i> toitinban.com </h1></a>
                Xây dựng niềm tin, giữ gìn tình cảm
            </div>
            {name ? <div className='is-inline'><button type='button' className='nes-btn is-error' onClick={() => resetName()}>Chọn tên khác ({name})</button></div> : <></>}
        </header >
    );
}

export default Header;