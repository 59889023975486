import React from 'react';

const WWCard = ({ src, role }) => {
  return (
    <>
      <img src={src} width={"100%"} alt={role} />
    </>
  );
}

export default WWCard;