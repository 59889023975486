import React, { useEffect, useState } from 'react';
import { socket } from '../socket';
import './room-lead-form.scss';

const RoomLeadForm = (props) => {


  let lsPlayer = props.listPlayer.filter(item => item.id !== socket.id)
  const [selectedOption, setSelectedOption] = useState(2);
  const [checkboxes, setCheckboxes] = useState(props.rolesList);


  useEffect(() => {
    console.log(props.rolesList);
    setCheckboxes(props.rolesList);
  }, [props.rolesList]);

  function onStartGame() {
    const checkedItemIds = checkboxes.filter(item => item.checked === true).map(item => item.id);
    socket.emit('start game', checkedItemIds, lsPlayer, selectedOption);
  }

  function onEndGame() {
    socket.emit('end game', lsPlayer);
  }


  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
  };

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };


  const wolf = [{
    value: 1,
    label: "1 Sói"
  },
  {
    value: 2,
    label: "2 Sói"
  },
  {
    value: 3,
    label: "3 Sói"
  },
  {
    value: 4,
    label: "4 Sói"
  },
  {
    value: 5,
    label: "5 Sói"
  }];

  return (
    <div className='room-lead'>
      <div className='form-inline'>
        <button onClick={onStartGame} className='nes-btn is-success'>Start game</button>
        <button onClick={onEndGame} className='nes-btn is-error'>End game</button>
      </div>
      <br />
      <div class="nes-container with-title">
        <p class="title">Số lượng sói</p>
        {wolf.map((w, index) =>
          <p key={index}>
            <input
              type="radio"
              id={"wolf" + w.value}
              value={w.value}
              checked={selectedOption === w.value}
              onChange={handleOptionChange}
            />
            <label htmlFor={"wolf" + w.value}>
              {w.label}
            </label>
          </p>
        )}
      </div>

      <div class="nes-container with-title">
        <p class="title">Chức năng</p>
        {
          checkboxes.map((role, index) =>
            <p key={index}>
              <input
                type="checkbox"
                id={"role" + role.id}
                value={role.id}
                checked={role.checked}
                onChange={() => handleCheckboxChange(role.id)}
              />
              <label htmlFor={"role" + role.id}>
                {role.name}
              </label>
            </p>
          )
        }
      </div>

      <br />
      <div className='form-inline'>
        <button onClick={onStartGame} className='nes-btn is-success'>Start game</button>
        <button onClick={onEndGame} className='nes-btn is-error'>End game</button>
      </div>
    </div>
  );
}

export default RoomLeadForm;