import React, { useState } from 'react';
import { socket } from '../socket';

const JoinForm = () => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (value === "") {
      setIsLoading(false);
      setError('Tên không được để trống');
      return;
    }
    socket.emit('join game', value);
    socket.timeout(5000).emit('join', value, () => {
      setIsLoading(false);
    });
  }

  return (
    <form onSubmit={onSubmit} className='form-break-line'>
      <div className="nes-field">
        <label for="name_field">Nhập tên vào đây:</label>
        {error ? <span className="nes-text is-error">{error}</span> : <></>}
        <input onChange={e => setValue(e.target.value)} type="text" id="name_field" className="nes-input" />
      </div>
      <button type="submit" disabled={isLoading} className='nes-btn is-primary'><i className="nes-icon heart is-small"></i > Tham gia ngay</button>
    </form>
  );
}

export default JoinForm;