import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? "https://play.toitinban.com" : 'http://localhost:3000';

export const socket = io(URL, {
  query: "room="+window.location.pathname.replace(/\//g, ''),
  autoConnect: true,
  reconnection: true, // Enable reconnection
  reconnectionAttempts: Infinity, // Number of attempts before giving up
  reconnectionDelay: 1000, // Time to wait before attempting a new reconnection
  reconnectionDelayMax: 5000, // Maximum time to wait between reconnections
  randomizationFactor: 0.5 // Randomization factor between reconnections
});